import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
  }

  submit(event) {
    event.preventDefault();

    const data = new FormData(this.formTarget);
    fetch(this.formTarget.action, {
      method: "POST",
      body: data,
      headers: {
        "X-CSRF-Token": this.csrfToken,
        Accept: "application/json"
      }
    })
      .then((response) => response.json())
      .then((json) => {
        console.log("json: ");
        console.log(json);

        if (json.downloadUrl) {
          window.location.href = json.downloadUrl;
        } else if (json.error) {
          alert(json.error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Something went wrong, please try again");
      });
  }
}
